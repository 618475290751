import { useEffect, useState } from "react";
import style from "./CountDown.module.css";

type CountDownState = {
  date: number;
  years: number;
  months: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  onlyCountDown: boolean;
};

const initialState = (onlyCountDown = false): CountDownState => ({
  date: Date.parse("31 Aug 2019 15:00:00 GMT+1"),
  years: 0,
  months: 0,
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
  onlyCountDown,
});

const units: Record<string, number> = {
  seconds: 1000,
  minutes: 1000 * 60,
  hours: 1000 * 60 * 60,
  days: 1000 * 60 * 60 * 24,
  months: 1000 * 60 * 60 * 24 * 30,
  years: 1000 * 60 * 60 * 24 * 365,
};

const updateAll = (remaining: number, ...fns: any) => {
  let rem = remaining;
  fns.forEach((fn: (rem: number) => number) => {
    rem = fn(rem);
  });
};

const updateCounter = (
  updater: (state: Partial<CountDownState>) => void,
  key: string
) => (remaining: number): number => {
  const unit = units[key];
  const div = remaining / unit;
  const num = Math.floor(div);
  updater({ [key]: num });
  return remaining - num * unit;
};

const CountDown = (props: any) => {
  const [state, stateSet] = useState<CountDownState>(initialState());
  useEffect(() => {
    const id = setInterval(() => {
      const remaining = Math.abs(state.date - Date.now());
      const updater = (change: Partial<CountDownState>) => {
        stateSet((state) => ({ ...state, ...change }));
      };
      updateAll(
        remaining,
        updateCounter(updater, "years"),
        updateCounter(updater, "months"),
        updateCounter(updater, "days"),
        updateCounter(updater, "hours"),
        updateCounter(updater, "minutes"),
        updateCounter(updater, "seconds")
      );
    }, 500);
    return () => clearInterval(id);
  }, [state]);

  return (
    <div className={style.CountDown} style={props.style}>
      <div className={style.Counter}>
        <span className={style.CounterValue}>{state.years}</span>
        <span className={style.CounterName}>years</span>
      </div>
      <div className={style.Counter}>
        <span className={style.CounterValue}>{state.months}</span>
        <span className={style.CounterName}>months</span>
      </div>
      <div className={style.Counter}>
        <span className={style.CounterValue}>{state.days}</span>
        <span className={style.CounterName}>days</span>
      </div>
      <div className={style.Counter}>
        <span className={style.CounterValue}>{state.hours}</span>
        <span className={style.CounterName}>hours</span>
      </div>
      <div className={style.Counter}>
        <span className={style.CounterValue}>{state.minutes}</span>
        <span className={style.CounterName}>minutes</span>
      </div>
      <div className={style.Counter}>
        <span className={style.CounterValue}>{state.seconds}</span>
        <span className={style.CounterName}>seconds</span>
      </div>
    </div>
  );
};

export default CountDown;
