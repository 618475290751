import { useEffect, useState } from "react";

export interface LazyImageProps {
  loRes: string;
  hiRes: string;
  className: string;
}

const LazyImage = (props: LazyImageProps) => {
  const { loRes, hiRes, ...rest } = props;
  const [image, imageSet] = useState(loRes);

  useEffect(() => {
    const image$ = new Image();
    image$.src = hiRes;
    image$.addEventListener(
      "load",
      () => {
        imageSet(hiRes);
      },
      { once: true }
    );
  }, [hiRes, imageSet]);

  return <div style={{ backgroundImage: `url(${image})` }} {...rest} />;
};

export default LazyImage;
