import Leaf from "../Assets/img/leaf-1.png";
import style from "./LeafSpacer.module.css";

const LeafSpacer = ({
  image,
  alt,
  className,
}: {
  image: string;
  alt: string;
  className: string;
}) => {
  const id = (i: any) => i;
  return (
    <div className={style.Wrapper}>
      <img
        className={[style.Image, className].filter(id).join(" ")}
        src={image || Leaf}
        alt={alt || ""}
      />
    </div>
  );
};

export default LeafSpacer;
