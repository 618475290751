import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

(function () {
  // Issue with CloudFlare prevents us from doing the
  // redirect there. This will redirect to www in production
  // skipping the React part during the redirect
  if (process.env.REACT_APP_STAGE === "production") {
    if (!window.location.host.startsWith("www")) {
      window.location.replace("https://www." + window.location.host);
      return;
    }
  }

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();
