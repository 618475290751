import { useState } from "react";
import style from "./GettingThere.module.css";
import LeafSpacer from "../Components/LeafSpacer";
import SwimRing from "../Assets/img/swim-ring.png";
import Parachute from "../Assets/img/parachute.png";

const GettingThere = () => {
  const [fly, flySet] = useState(false);
  const toggleFly = () => flySet(!fly);
  return (
    <div className="content">
      <section className="section section--odd has-title">
        <div className="container">
          <h2>Getting There</h2>
          <img
            src={Parachute}
            onClick={toggleFly}
            className={fly ? style.ParachuteFlying : style.Parachute}
            alt="Buckle up"
          />
          <p>
            We prepared this section primarily for our guests who are traveling
            from afar to attend the wedding. If you’re a Danish resident, you
            can also use this section to guide you on some travel logistics and
            on recommended accommodations in Kerteminde.
          </p>
        </div>
      </section>
      <section className="section">
        <div className="container columns">
          <h3>The Wedding Location</h3>
          <p>
            Lundsgaard Gods is a privately-run farm estate located in the harbor
            town of Kerteminde, on the charming island of Fyn. Dubbed as the
            country’s ‘Garden Island’, Fyn is the birth place of Hans Christian
            Andersen, one of Denmark’s—nay, the world’s—best-loved writers.
          </p>
          <p>
            With its gently rolling hills, lush woodlands, idyllic farmhouses
            and the sea for a backdrop, you will see why we chose Lundsgaard
            Gods as our venue for the wedding.
          </p>
          <p>
            You’ll also find out, possibly as a first-time traveler to Denmark,
            that you’ve been pronouncing the name Hans Christian Andersen the
            wrong way all your life.
          </p>

          <div className={style.Trivia}>
            Lundsgaard Gods is the family estate of Poul Henningsen, the
            renowned Danish lamp designer. To this day, you can expect a typical
            Danish household to have lighting fixtures inspired by Henningsen’s
            iconic lamp designs.
          </div>
        </div>
      </section>
      <section className="section section--odd">
        <div className="container columns">
          <h3 className="has-subtitle">Traveling to Lundsgaard Gods</h3>
          <h4>For tourists and non-residents</h4>
          <p>
            The most convenient airport to land in would be Copenhagen Airport
            (CPH). If Copenhagen is not a good option for you, another airport
            to consider is Billund.
          </p>
          <p>
            If you have already RSVP’d, we suggest booking your flights as early
            as possible, as the date of the wedding falls within peak travel
            season.
          </p>

          <p>
            From Copenhagen Airport Terminal 3, take the train to Copenhagen
            Central Station (København H). The train runs every 10 minutes
            during the day and will get you to Copenhagen Central Station in
            about 13 minutes. During the night, the trains run 1-3 times an
            hour.
          </p>

          <p>
            From Copenhagen Central Station, book a train to Nyborg Station. The
            trains to Nyborg run several times within each hour. Once you reach
            Nyborg, take the bus and go down at Hinkesvej (<em>hinke</em> =
            hopscotch; <em>vej</em> = road) in Kerteminde. From there, do check
            with your hotel or AirBnB host for further instructions on how to
            reach your lodgings. Buses and taxis are available in Hinkesvej.
          </p>

          <p className={style.Trivia}>
            You can book your train ticket online on{" "}
            <a href="https://dsb.dk">DSB.dk</a> before your trip. Some trains
            run directly from Copenhagen Airport to Nyborg Station (Nyborg St.).
          </p>

          <p>
            For more details on how to plan your route, we suggest that you
            visit{" "}
            <a href="https://www.rome2rio.com/s/Copenhagen/Kerteminde">
              Rome 2 Rio's transportation guide Copenhagen to Kerteminde
            </a>
            .
          </p>
          <p>
            To check on plane fares, we highly recommend{" "}
            <a href="https://www.momondo.com">Momondo.com</a>.
          </p>
          <h4>For Danish residents</h4>
          <p>
            Take your car, take your suit (and the kids too), and use Google
            Maps to navigate your way to the wedding venue.
          </p>
        </div>
      </section>
      <section className="section">
        <div className="container columns">
          <h3>Visa and other Pre-Travel Logistics</h3>
          <p>
            For non-EU residents, applying for a Schengen visa can be a real
            chore. Do contact the Danish consulate or visa processing agency
            nearest you and check on what you need to submit for a Schengen visa
            application.
          </p>

          <p>
            For those living in the Philippines, please visit{" "}
            <a href="www.vfsglobal.com/denmark/philippines/">VFS Global</a>.
          </p>
          <p>
            The Embassy of Denmark in the Philippines is crazy efficient, and
            you can expect to receive your visa as early as 2-4 working days
            upon submission of correct and complete documents. Maximum
            processing time is 15 working days. (So do avoid applying at the
            last minute, because August is peak season in Denmark.)
          </p>

          <p>
            You can apply for a Schengen visa 1-3 months before the wedding
            date. Do not submit more than 3 months before your planned
            departure, as your application will not be accepted.
          </p>

          <p>
            We want to make the visa application process a little easier for
            you. Aside from the electronic Save the Date and the printed
            official wedding invitation, which will be sent out to you by March
            or April 2019, we can provide you with a rough template of a cover
            letter addressed to the Embassy of Denmark as well as a travel
            itinerary -- both of which you must customize on your own based on
            your personal flight details and travel plans.
          </p>
          <p>
            Just send us an e-mail to{" "}
            <a href="mailto:info@gina-and-michael.com">
              info@gina-and-michael.com
            </a>{" "}
            if you need these sample travel documents.
          </p>
        </div>
        <LeafSpacer />
      </section>
      <section className="section section--odd">
        <div className="container columns">
          <h3>Hotels and Accommodations</h3>
          <p>
            Kerteminde Kommune (Municipality) is quite a small place and has
            only about 20 hotels/hostels (to suit a range of budgets) within the
            area.
          </p>
          <p>Recommended Lodgings:</p>
          <ul>
            <li>
              <a href="http://tornoeshotel.dk/">Tornøes Hotel</a>
            </li>
            <li>
              <a href="http://kertemindebedbreakfast.dk/en/">
                Kerteminde Bed &amp; Breakfast
              </a>
            </li>
            <li>
              <a href="http://roedkaergaard.dk/">
                Rødkærgård Bed &amp; Breakfast
              </a>
            </li>
            <li>
              <a href="https://www.danhostel.dk/en/hostel/danhostel-kerteminde">
                Danhostel Kerteminde
              </a>
            </li>
            <li>
              <a href="https://www.bnb-kerteminde.dk/en/">
                Købmandsgaarden B&B
              </a>
            </li>
          </ul>
          <p>
            You can book via <a href="https://booking.com">Booking.com</a> or{" "}
            <a href="https://agoda.com">Agoda</a>, but we also recommend
            contacting the hotel or hostel directly to inquire on availability
            of rooms, as August falls within peak season.
          </p>

          <p>
            Booking an{" "}
            <a href="https://www.airbnb.com/s/Kerteminde--Denmark/homes?query=Kerteminde%2C%20Denmark">
              AirBnB in Kerteminde
            </a>{" "}
            is highly recommended, especially if you are traveling as a group.
            Don’t worry if your AirBnB looks a bit far from the wedding venue
            when you check it on Google Maps. There’s hardly any traffic in
            Denmark, and every venue is just a 10-minute (or less!) taxi ride
            away from one another.
          </p>

          <p>
            From your hotel or AirBnB in Kerteminde, you can easily book a taxi
            to get to the wedding venue.
          </p>

          <div className={style.Trivia}>
            You also have the option to stay in Copenhagen during the wedding,
            but please allot 2 to 2.5 hours of travel time each way, as the
            distance between Copenhagen and Kerteminde is over 120 km. Do take
            note of the train schedules if you wish to make it back to
            Copenhagen late in the evening after the wedding reception.
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container columns">
          <h3>Language</h3>
          <p>
            If you don’t speak Danish, forget about doing a Rosetta Stone crash
            course on the language during your long-haul flight to Copenhagen.
            Danish is a notoriously difficult language to learn, and chances
            are, you’ll still end up mispronouncing 80% of the basic phrases
            you’ve looked up on Google unless you happen to be a polyglot.
          </p>

          <p>
            It’s also true what they say—Danes sound like there’s a hot potato
            stuck in their throats whenever they’re speaking. This makes all
            your French lessons back in university seem like a walk in the park.
          </p>

          <p>
            But if you’re up to the challenge, you’ll be able to learn some very
            funny and charming everyday Danish expressions during your stay.
          </p>

          <p>
            Citizens take great pride in speaking in the mother tongue by
            default, but English is also widely spoken and understood in major
            areas in Denmark such as Kerteminde.
          </p>

          <p>
            <em>Stativ, stakit, kasket.</em>
          </p>
        </div>
      </section>
      <section className="section section--odd">
        <div className="container">
          <h3>Internet access</h3>
          <p>
            Denmark has at least three mobile Internet service providers. If you
            intend to be completely online during your stay, we recommend that
            you bring with you a USB Internet dongle modem or an unlocked
            smartphone with you and then just purchase a Danish prepaid SIM card
            loaded with mobile data when you arrive at Copenhagen Airport.
          </p>
          <p>
            Many hotels, restaurants, and tourist sites in Denmark offer free
            Wi-Fi, so you’ll never be completely off the grid. Airports and
            trains also provide free Wi-Fi. Such a lovely, connected country.
          </p>
          <h3>Voltage</h3>
          <p>
            Denmark, like most European countries, uses 220V, 50 Hz. The
            electric plug has two round pins. So do bring a universal travel
            adapter to avoid any inconvenience.
          </p>
          <h3>Currency</h3>
          <p>
            The Kingdom of Denmark uses both Euro (EUR) and Danish Kroner (DKK).
          </p>
          <p>
            USD 1 = DKK 6.5
            <br />
            USD 1 = EUR 0.88
          </p>
          <p>
            Visit{" "}
            <a href="www.oanda.com/currency/converter/">
              Oanda Currency Converter
            </a>{" "}
            regularly to check on the latest currency exchange.
          </p>{" "}
          <p>
            Currency exchange kiosks and ATMs are available in Copenhagen
            Airport upon arrival. But we also highly recommend that you use your
            credit card and/or debit card when making a purchase – from train
            tickets to taxi rides to food and even for that tube of toothpaste
            that you’re desperate to buy at a Danish 7-Eleven because you forgot
            yours at home. Denmark is practically a cashless economy, so you
            don’t have to worry about carrying wads of cash around with you.
          </p>
        </div>
        <LeafSpacer />
      </section>
      <section className="section">
        <div className={"container " + style.ContactSection}>
          <div>
            <h3>Contact numbers</h3>
            <p>Shoot us a message for any questions or reactions!</p>
            <p>
              Via e-mail
              <br />
              <a href="mailto:info@gina-and-michael.com">
                info@gina-and-michael.com
              </a>
            </p>
            <p>
              Via SMS, Viber, Telegram, or WhatsApp
              <br />
              + 63 917 8849717 (Gina)
              <br />
              + 63 977 8141980 (Michael)
              <br />
            </p>
          </div>
          <img src={SwimRing} className={style.SwimRing} alt="Contact" />
        </div>
      </section>
    </div>
  );
};
export default GettingThere;
