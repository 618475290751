import { useState } from "react";
import { useLocation } from "react-router-dom";
import MobileLink from "./MobileLink";
import "./MobileNav.css";

const MobileNav = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const classNames = ["MobileNav"];
  if (open) {
    classNames.push("MobileNav--open");
  }
  if (location.pathname === "/") {
    classNames.push("MobileNav--inverted");
  }
  return (
    <nav className={classNames.join(" ")}>
      <div className="MobileNav-toggle" onClick={toggle}>
        <div className="MobileNav-toggle__stick" />
        <div className="MobileNav-toggle__stick" />
        <div className="MobileNav-toggle__stick" />
      </div>
      <ul className="MobileNav-menu">
        <li>
          <MobileLink to="/" exact onClick={toggle}>
            Home
          </MobileLink>
        </li>
        <li>
          <MobileLink to="/#our-story" onClick={toggle}>
            Our Story
          </MobileLink>
        </li>
        <li>
          <MobileLink to="/#the-big-day" onClick={toggle}>
            The Big Day
          </MobileLink>
        </li>
        <li>
          <MobileLink top to="/getting-there" onClick={toggle}>
            Getting There
          </MobileLink>
        </li>
        <li>
          <MobileLink top to="/rsvp" onClick={toggle}>
            RSVP
          </MobileLink>
        </li>
        <li>
          <MobileLink top to="/gift-registry" onClick={toggle}>
            Gift Registry
          </MobileLink>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNav;
