import React, { useEffect, useState } from "react";
import style from "./Foliage.module.css";
import LoRes from "../Assets/img/foliage.png";
import HiRes from "../Assets/img/foliage@2x.png";

const Foliage = () => {
  const [image, imageSet] = useState(LoRes);

  useEffect(() => {
    const image$ = new Image();
    image$.src = HiRes;
    image$.addEventListener(
      "load",
      () => {
        imageSet(HiRes);
      },
      { once: true }
    );
  }, [imageSet]);

  return (
    <div
      className={style.Foliage}
      style={{ backgroundImage: `url(${image})` }}
    />
  );
};

export default Foliage;
