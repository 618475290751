import { NavHashLink as NavLink } from "react-router-hash-link";

const MobileLink = (props: any) => {
  const onClick = props.top
    ? () => window.scrollTo({ top: 0, behavior: "smooth" })
    : () => {};
  const onClick_ = () => {
    props.onClick();
    onClick();
  };
  return (
    <NavLink
      to={props.to}
      exact={props.exact}
      smooth
      onClick={onClick_}
      location={{
        pathname: document.location.pathname + document.location.hash,
      }}
    >
      {props.children}
    </NavLink>
  );
};

export default MobileLink;
