import React from "react";
import TinderApp from "../Assets/img/tinder-app.jpg";
import TinderBeach from "../Assets/img/tinder-beach.jpg";
import TinderDude from "../Assets/img/tinder-mblarsen.jpg";
import TinderTaal from "../Assets/img/tagaytay-enactment.gif";
import style from "./Dude.module.css";

const preloadImages = [TinderApp, TinderBeach, TinderTaal, TinderDude];
preloadImages.forEach((i) => {
  const image = new Image();
  image.src = i;
});

class Dude extends React.Component<{ theMeet: Function; close: Function }> {
  constructor() {
    super();
    this.state = {
      sliding: false,
      snapTimer: undefined,
    };
    this.screen = React.createRef();
  }
  slide(event, initSlide = null, behavior = null) {
    if (this.state.sliding) {
      // event && event.preventDefault();
      setTimeout(() => this.setState({ sliding: false }), 350);
      return;
    }
    if (this.snapTimer) {
      clearTimeout(this.snapTimer);
    }
    const snapTimer = setTimeout(() => {
      const slides = 3;
      const left = this.screen.current.scrollLeft;
      const width = this.screen.current.scrollWidth;
      const slide = Number.isInteger(initSlide)
        ? initSlide
        : Math.round((left / width) * slides);
      this.setState({ sliding: true });
      this.screen.current.scroll({
        left: slide * (width / slides),
        behavior: behavior || "smooth",
      });
    }, 500);
    this.setState({ snapTimer });
  }
  componentDidMount() {
    this.slide(null, 0, "auto");
  }
  render() {
    return (
      <div className={style.Wrapper}>
        <div className={this.props.theMeet ? style.PhoneOnScreen : style.Phone}>
          <img alt="TinderApp" src={TinderApp} className={style.Image} />
          <div ref={this.screen} className={style.Screen}>
            <div
              className={style.Slider}
              onScroll={this.slide.bind(this)}
              onWheel={this.slide.bind(this)}
            >
              <div className={style.Slide}>
                <img src={TinderBeach} alt="Beach" />
              </div>
              <div className={style.Slide}>
                <img src={TinderDude} alt="Dude" />
              </div>
              <div className={style.Slide}>
                <img
                  src={TinderTaal}
                  style={{ objectFit: "cover" }}
                  alt="Taal"
                />
              </div>
            </div>
          </div>
          <div className={style.Button} onClick={this.props.close} />
        </div>
      </div>
    );
  }
}

export default Dude;
