import style from "./TheBigDay.module.css";
import Attire from "../Assets/img/attire.png";
import Anexet from "../Assets/img/anexet.jpg";
import LundsgaardGods from "../Assets/img/lundsgaardgods.jpg";

const TheBigDay = () => {
  return (
    <div className={style.Wrapper}>
      <div className={style.Ceremony}>
        <h3>Wedding Ceremony</h3>
        <div className={style.EventTime}>Three o’ clock in the afternoon</div>
        <div className={style.EventLocation}>
          In the garden of Forvalterboligen by Lundsgaard Gods
        </div>
      </div>
      <img
        className={style.LundsgaardGods}
        src={LundsgaardGods}
        alt="LundsgaardGods"
      />
      <div className={style.CeremonyDescription}>
        <p>
          Join us as we say our vows in a garden wedding ceremony on the grounds
          of a country farm estate, Lundsgaard Gods. It has always been our
          dream to tie the knot surrounded by nature.
        </p>
        <p>
          After the ceremony, cocktails and snacks will be served. There will be
          enough time for you to explore the grounds and enjoy the tail-end of
          the Danish summer before the reception starts.
        </p>
      </div>
      <div className={style.Reception}>
        <h3>Wedding Reception</h3>
        <div className={style.EventTime}>Six o’ clock in the evening </div>
        <div className={style.EventLocation}>
          Anexet at Lundsgaard Gods
          <br /> (across the courtyard from Forvalterboligen)
        </div>
      </div>
      <img className={style.Anexet} src={Anexet} alt="Anexet" />
      <div className={style.ReceptionDescription}>
        <p>
          The Anexet is used year-round as a cultural venue for the arts and
          music and for other social events. Food at the wedding reception will
          be prepared by Café Agnes H, which is next door to the Anexet. Café
          Agnes H is run by Thomas Kurek who, together with the Anexet’s
          proprietors Stephen Freiheit and Rudolf and Vini Iluel, serve as the
          main driving forces of Lundsgaard Gods’ social and cultural scene.
        </p>

        <p>
          The Danes are fond of alcohol, so we look forward to merrily
          celebrating with you over a glass (or two, or five) of beer or wine.
        </p>

        <p>
          If you have any food restrictions or allergies, please let us know
          ahead of time.
        </p>
      </div>
      <div className={style.Other}>
        <img className={style.AttireImg} src={Attire} alt="Garden Formal" />
        <div>
          <p className={style.Attire}>
            Attire for Ceremony, Cocktails, and Reception is{" "}
            <strong>Garden Formal</strong>.
          </p>
          <p>
            Summer temperature in Denmark can range from 14 to 23 degrees
            Celsius. Ladies, do bring something to cover your shoulders and arms
            in case the weather becomes a little nippy for you.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TheBigDay;
