import DesktopNav from "./Components/DesktopNav";
import MobileNav from "./Components/MobileNav";
import ToTop from "./Components/ToTop";

import FrontPage from "./Pages/FrontPage";
import GettingThere from "./Pages/GettingThere";
import GiftRegistry from "./Pages/GiftRegistry";
import RSVP from "./Pages/RSVP";
import Foliage from "./Components/Foliage";
import Footer from "./Components/Footer";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

function App() {
  return (
    <Router>
      <Route
        render={({ location }) => (
          <div>
            <DesktopNav />
            <MobileNav />
            <Foliage />
            <ToTop />
            <Switch location={location}>
              <Route path="/" exact component={FrontPage} />
              <Route path="/getting-there" component={GettingThere} />
              <Route path="/gift-registry" component={GiftRegistry} />
              <Route path="/rsvp/:id?" component={RSVP} />
            </Switch>
            <Footer />
          </div>
        )}
      />
    </Router>
  );
}

export default App;
