import { useEffect, useState } from "react";
import RSVPForm from "../Components/RSVPForm";
import RSVPLogin from "../Components/RSVPLogin";
import RSVPThankYou from "../Components/RSVPThankYou";
import * as Api from "../api/index";

type RsvpAttendance = {
  attending: number;
  attendingMax: number;
  inviteName: string;
};

function fromStorage(): RsvpAttendance | undefined {
  if (window.sessionStorage.getItem("rsvp")) {
    const json: string = window.sessionStorage.getItem("rsvp")!;
    try {
      const rsvp = JSON.parse(json);
      if (!rsvp) {
        throw new Error("Could not decode RSVP");
      }
      return rsvp;
    } catch (error) {
      window.sessionStorage.removeItem("rsvp");
      throw error;
    }
  }
  return;
}

async function handleResponse(
  promise: Promise<RsvpAttendance>
): Promise<RsvpAttendance> {
  const rsvpAttendance = await promise;
  window.sessionStorage.setItem("rsvp", JSON.stringify(rsvpAttendance));
  return rsvpAttendance;
}

const optimisticEmailValidation = (email: string) =>
  email.match(/^[0-9a-z_.-]+@[0-9a-z_.-]+\.[a-z.-]+$/i);

type RsvpState = {
  rsvp: RsvpAttendance;
  rsvped: boolean;
  email: string;
};

const Rsvp = () => {
  const [busy, busySet] = useState(false);
  const [error, errorSet] = useState<Error | undefined>(undefined);
  const [state, stateSet] = useState<RsvpState>({
    rsvp: { inviteName: "", attending: 0, attendingMax: 0 },
    rsvped: false,
    email: "",
  });

  useEffect(() => {
    const rsvp = fromStorage();
    stateSet((state) => ({ ...state, rsvped: Boolean(rsvp) }));
  }, []);

  const lookup = async (event: Event) => {
    event && event.preventDefault();
    if (state.email && optimisticEmailValidation(state.email)) {
      errorSet(undefined);
      busySet(true);
      try {
        const rsvpAttendance = await handleResponse(Api.lookup(state.email));
        stateSet((state) => ({ ...state, rsvp: rsvpAttendance, rsvped: true }));
      } catch (error) {
        errorSet(error);
      } finally {
        busySet(false);
      }
    }
  };

  const inputHandler = (event: InputEvent) => {
    const input = event?.target as HTMLInputElement;
    if (input?.name) {
      stateSet((state) => ({
        ...state,
        [input.name]: input?.value,
      }));
    }
  };

  const onUp = () => {
    let attending = state.rsvp.attending + 1;
    attending =
      attending > state.rsvp.attendingMax ? state.rsvp.attendingMax : attending;
    stateSet((state) => ({ ...state, rsvp: { ...state.rsvp, attending } }));
  };

  const onDown = () => {
    let attending = state.rsvp.attending - 1;
    attending = attending < 0 ? 0 : attending;
    stateSet((state) => ({
      ...state,
      rsvp: { ...state.rsvp, attending },
    }));
  };

  const rsvpInputHandler = (event: InputEvent) => {
    const input = event?.target as HTMLInputElement;
    if (input?.name) {
      stateSet((state) => ({
        ...state,
        rsvp: { ...state.rsvp, [input?.name]: input.value },
      }));
    }
  };

  const changeRSVP = (event: Event) => {
    event.preventDefault();
    stateSet((state) => ({
      ...state,
      rsvped: false,
      rsvp: {
        ...state.rsvp,
        attending: state.rsvp.attendingMax === 1 ? 1 : state.rsvp.attending,
      },
    }));
  };

  const submitRsvp = async (event: Event) => {
    event.preventDefault();
    const action = (event.target as HTMLInputElement)?.name!;
    busySet(true);

    try {
      await handleResponse(
        Api.submit(state.email, action === "decline" ? 0 : state.rsvp.attending)
      );
      stateSet((state) => ({ ...state, rsvped: true }));
    } catch (error) {
      errorSet(error);
    } finally {
      busySet(false);
    }
  };

  let Component = RSVPLogin;
  if (state.rsvp?.inviteName) {
    if (state.rsvped) {
      Component = RSVPThankYou;
    } else {
      Component = RSVPForm;
    }
  }

  return (
    <>
      <section className="section section--odd has-title">
        <div className="container">
          <h2>{state.rsvp ? state.rsvp.inviteName : "You're invited!"}</h2>
          <Component
            onUp={onUp}
            onDown={onDown}
            rsvpInputHandler={rsvpInputHandler}
            rsvp={state.rsvp}
            error={error}
            busy={busy}
            state={state}
            inputHandler={inputHandler}
            lookUp={lookup}
            changeRSVP={changeRSVP}
            onConfirm={submitRsvp}
            onDecline={submitRsvp}
          />
        </div>
      </section>
    </>
  );
};

export default Rsvp;
