import { NavHashLink as NavLink } from "react-router-hash-link";

const DesktopLink = (props: any) => {
  const onClick = props.top
    ? () => window.scrollTo({ top: 0, behavior: "smooth" })
    : () => {};
  return (
    <NavLink
      to={props.to}
      smooth
      onClick={onClick}
      location={{
        pathname: document.location.pathname + document.location.hash,
      }}
    >
      {props.children}
    </NavLink>
  );
};

export default DesktopLink;
