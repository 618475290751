import loginStyles from "./RSVPLogin.module.css";
import formStyles from "./RSVPForm.module.css";

const RSVPLogin = ({ inputHandler, lookUp, state, busy, error }: any) => {
  return (
    <div className={loginStyles.Wrapper}>
      <p>Please enter your e-mail address to unlock your RSVP form.</p>
      <div className="field" style={{ textAlign: "center" }}>
        <form>
          <input
            type="email"
            name="email"
            className={loginStyles.Email + " has-button"}
            autoComplete="off"
            value={state.email}
            onChange={inputHandler}
            onKeyDown={(e) => e.key === "Enter" && lookUp(e)}
            placeholder="Enter your e-mail"
          />
          <button onClick={lookUp} disabled={busy}>
            {busy ? <span className={formStyles.Loader} /> : "continue"}
          </button>
        </form>
      </div>
      <div className={loginStyles.EventEnded}>
        The event is over and all invitations has been archived. You can still
        relive the RSVP™ experience by entering the e-mail:{" "}
        <em>invited@example.com</em>.
      </div>
      {error && (
        <div className={formStyles.Error}>
          We couldn't find an invitation with that e-mail address. Please check
          that you've entered it correctly and that it is the same one where you
          received your invitation.
        </div>
      )}
    </div>
  );
};

export default RSVPLogin;
