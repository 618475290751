import { useLayoutEffect, useState } from "react";
import style from "./ToTop.module.css";

const toTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

const ToTop = () => {
  const [show, setShow] = useState(false);
  useLayoutEffect(() => {
    const scrollHandler = function () {
      if (window.scrollY > 300) {
        setShow(true);
      } else {
        setShow(false);
      }
    };

    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  });

  return (
    <div
      onClick={toTop}
      title="To the top"
      className={show ? style.ToTop : style.ToTopHidden}
    >
      <div
        className={
          style.Circle + " " + (show ? style.CircleIn : style.CircleOut)
        }
      />
    </div>
  );
};

export default ToTop;
