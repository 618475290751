import { useEffect, useState } from "react";
import DesktopLink from "./DesktopLink";
import "./DesktopNav.css";

const DesktopNav = () => {
  const [stuck, stuckSet] = useState(false);
  useEffect(() => {
    const handler = () => {
      if (window.scrollY > 150) {
        stuckSet(true);
      } else if (window.scrollY < 30) {
        stuckSet(false);
      }
    };
    window.addEventListener("scroll", handler);
    return () => {
      window.removeEventListener("scroll", handler);
    };
  }, []);
  return (
    <nav className={stuck ? "DesktopNav DesktopNav--narrow" : "DesktopNav"}>
      <ul>
        <li className="NavCouple">
          <a href="/">Gina &amp; Michael</a>
        </li>
        <li>
          <DesktopLink to="/#our-story">Our Story</DesktopLink>
        </li>
        <li>
          <DesktopLink to="/#the-big-day">The Big Day</DesktopLink>
        </li>
        <li>
          <DesktopLink top to="/getting-there">
            Getting There
          </DesktopLink>
        </li>
        <li>
          <DesktopLink top to="/rsvp">
            RSVP
          </DesktopLink>
        </li>
        <li>
          <DesktopLink top to="/gift-registry">
            Gift Registry
          </DesktopLink>
        </li>
      </ul>
    </nav>
  );
};

export default DesktopNav;
