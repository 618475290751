import Estate from "../Assets/img/estate.png";
import CountDown from "./CountDown";
import style from "./RSVPThankYou.module.css";

const RSVPThankYou = ({ changeRSVP, rsvp }: any) => {
  return (
    <div className="container is-centered">
      {rsvp.attending > 0 ? (
        <>
          <h3>Hurray, see you in Kerteminde!</h3>
          <img src={Estate} alt="Lundsgaard Gods" />
          <CountDown onlyCountDown={true} style={{ marginTop: 0 }} />
        </>
      ) : (
        <h3>Thank you for confirming!</h3>
      )}
      <div style={{ marginTop: "2rem" }}>
        <a onClick={changeRSVP} href="/rsvp" className={style.Change}>
          Change my RSVP
        </a>
      </div>
    </div>
  );
};
export default RSVPThankYou;
