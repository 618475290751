export const lookup = (email: string) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (email === "invited@example.com") {
        const people = Math.ceil(Math.random() * 3);
        resolve({
          attending: people,
          attendanceMax: people,
          inviteName: "Matilde",
        });
      } else {
        reject(new Error("Not invited"));
      }
    }, 1500);
  });
};

export const submit = (_email: string, _attending: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, 1500);
  });
};
