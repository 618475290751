import style from "./OurStory.module.css";
import Us from "../Assets/img/us.jpg";
import Bonfire from "../Assets/img/bonfire.png";
import BonfireFlipped from "../Assets/img/bonfire-flipped.png";
import Dude from "./Dude";
import ContentEditable from "react-contenteditable";
import { useState } from "react";

const preloadImages = [Bonfire, BonfireFlipped];

preloadImages.forEach((i) => {
  const image = new Image();
  image.src = i;
});

const StoryBeginning = () => {
  return (
    <>
      <p>On the 5th day of January in 2015, Boy meets Girl.</p>
      <p>And Boy gets ignored by Girl for the next five days.</p>
    </>
  );
};

function selectElementContents(el: HTMLElement) {
  let range = document.createRange();
  range.selectNodeContents(el);
  let sel = window.getSelection();
  sel?.removeAllRanges();
  sel?.addRange(range);
}

const Story = (props: any) => {
  return (
    <div>
      <StoryBeginning />
      <Dude theMeet={props.theMeet} close={props.closePhone} />
      <p>
        You see, their meet cute didn’t happen in a café or in a quiet, lovely
        park. Not even in some bookstore.
      </p>
      <p>
        Boy and Girl found each other in a hopeless online place called{" "}
        <ContentEditable
          tagName="span"
          html={props.appName}
          onClick={props.countClicks}
          onWheel={props.checkForSwipe}
          onChange={props.spellIt}
        />{" "}
        in an equally hopeless (love-wise) physical place called Nepal. He had
        been living there for the past six years, and she was there on holiday,
        off to do a third trek in the midst of winter.
      </p>
      <p>
        And so when they matched online, she wasn’t being coy or rude; she just
        didn’t have the time and Internet access while trekking to say hello to
        him.
      </p>
      <p>
        It was only when she returned to Manila on January 10 that she was able
        to message him back.
      </p>
      <p>And everything just moved at an accelerated pace after that.</p>
      <p>
        She didn’t know much about Denmark except for Danish cookies and Hans
        Christian Andersen. And he had zero knowledge of the Philippines. But
        somehow, they found a way to really click and enjoy each other’s company
        and bad jokes.
      </p>
      <p>
        Eleven months later—after thousands of messages on Viber, calls on Skype
        and FaceTime, and various meet-ups (two in Manila, one in Bangkok and
        another in New Delhi)—they both decided to put an end to this whole long
        distance nonsense and start a new life together in Manila.
      </p>
      <p>
        So far, it has been four years of laughter, joy, ice cream, traveling
        around Asia and Europe, and chasing after their dogs Loki and Remus.
        Four years of food tripping, arguing over petty things, bingeing on
        movies and TV shows, of his bad attempts to learn Filipino, and of her
        perpetual struggle to pronounce the ‘ø’ in his surname. Four years
        measured out in coffee spoons but always overflowing in <em>hygge</em>,{" "}
        <em>lykke</em>, and love.
      </p>
    </div>
  );
};

type OurStoryState = {
  path: string;
  help: boolean;
  fire: boolean;
  theMeet: boolean;
  clicks: number;
  appName: string;
};

const OurStory = () => {
  const [state, stateSet] = useState<OurStoryState>({
    path: "read",
    help: false,
    fire: false,
    theMeet: false,
    clicks: 0,
    appName: "T - - - - -",
  });

  const setState = (
    changer: (state: OurStoryState) => Partial<OurStoryState>
  ) => stateSet((state) => ({ ...state, ...changer(state) }));

  const lightFire = () =>
    setState(({ fire, theMeet }) => ({
      fire: !fire,
      theMeet: fire ? false : theMeet,
    }));

  const actions = {
    countClicks(event: Event) {
      selectElementContents(event?.target as HTMLElement);
      const needs = 3;
      setState(({ clicks, fire }) => ({
        clicks: clicks >= needs ? 0 : clicks + 1,
        theMeet: fire && clicks >= needs,
      }));
    },
    checkForSwipe() {
      setState(({ fire }) => ({ theMeet: fire }));
    },
    closePhone() {
      setState(() => ({ theMeet: false, fire: false }));
    },
    spellIt(event: Event) {
      const text = (event.target as HTMLInputElement).value || "";
      setState(({ fire }) => ({
        appName: text,
        theMeet: fire && text.toLowerCase() === "tinder",
      }));
    },
  };

  return (
    <div className={style.Wrapper}>
      <Story {...state} {...actions} />
      {state.path !== "none" && (
        <>
          <p style={{ textAlign: "center" }}>* * *</p>
          <p>
            If you’ve gotten this far without skipping to the{" "}
            <em>The Big Day</em> section of this website, <em>tak</em> (thank
            you) for bearing with us! We are rarely cheesy, and even making this
            wedding website has given us the most cringe-worthy moments.
            Nevertheless, we are so thrilled to have you be part of our special
            day—our love story, for that matter—and we cannot wait to see you in
            Kerteminde, Denmark!
          </p>
          <div className={style.Signature}>
            <img src={Us} className={style.Us} alt="Uses" />
            <div
              onClick={lightFire}
              className={state.fire ? style.TinderFire : style.Tinder}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default OurStory;
