import style from "./FrontPage.module.css";
import CountDown from "../Components/CountDown";
import OurStory from "../Components/OurStory";
import TheBigDay from "../Components/TheBigDay";
import { Link } from "react-router-dom";
import Rings from "../Assets/img/rings.png";
import LocationMap from "../Assets/img/map.jpg";

const FrontPage = () => {
  return (
    <>
      <section className="section">
        <div className="container">
          <h1 className={style.TheCouple}>
            <div className={style.Person}>Gina Sales</div>
            <div>and</div>
            <div className={style.Person}>Michael Bøcker-Larsen</div>
          </h1>
        </div>
      </section>
      <section className="section section--odd">
        <div className="container">
          <div className={style.TitleWrapper}>
            <div className={style.TheDate}>
              August 31, 2019
              <br />
              Saturday
            </div>
            <h4 className={style.TheLocation}>Kerteminde, Denmark</h4>
            <img src={Rings} className={style.Rings} alt="Rings" />
          </div>
        </div>
      </section>
      <div className="container" />
      <section className="section">
        <div className="container">
          <h2 id="our-story">Our Story</h2>
          <OurStory />
        </div>
      </section>
      <section className="section has-title section--odd">
        <div className="container">
          <h2 id="the-big-day">The Big Day</h2>
          <div className={style.Title}>
            Saturday, the 31<sup>st</sup> of August 2019
          </div>
          <CountDown style={{ margin: "4rem auto" }} />
          <TheBigDay />
        </div>
      </section>
      <section className="section">
        <div className="container content">
          <img
            src={LocationMap}
            alt="Location map"
            title="Map layout by Janina San Buenaventura"
          />
          <p style={{ textAlign: "center" }}>
            For more details have a look at the{" "}
            <Link
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              to="/getting-there"
            >
              Getting There
            </Link>{" "}
            section.
          </p>
        </div>
      </section>
    </>
  );
};

export default FrontPage;
