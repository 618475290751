const GiftRegistry = () => {
  return (
    <>
      <section className="section section--odd has-title">
        <div className="container">
          <h2>gift registry</h2>
          <p>
            Your presence on our wedding day is one of the best gifts you can
            ever give us.
          </p>
          <p>
            We recognize the massive effort it takes to plan your lives around
            our ‘I Dos’, and we are grateful to all of you who have RSVP’d so
            far.
          </p>
          <p>
            For those who are unable to make it—send us a card, an SMS, an
            Instagram story, a pigeon with a message. Those expressions of love
            will serve as tokens of your presence.
          </p>
          <p>
            And if you really insist, feel free to give a small cash gift on our
            wedding day to help us start our new life together.
          </p>
        </div>
      </section>
    </>
  );
};

export default GiftRegistry;
