import style from "./RSVPForm.module.css";

const RSVPForm = ({ rsvp, state, onUp, onDown, onConfirm, onDecline }: any) => {
  const introText =
    rsvp.attendingMax > 1
      ? `We have reserved a maximum of ${rsvp.attendingMax} seats for you.`
      : "We have reserved 1 seat for you";
  return (
    <div className={style.Wrapper}>
      {state.busy ? (
        <div className={style.Loader} style={{ margin: "4rem" }} />
      ) : (
        <>
          <div className={style.SeatsLabel}>{introText}</div>

          {rsvp.attendingMax > 1 && (
            <RSVPControls onUp={onUp} onDown={onDown} rsvp={rsvp} />
          )}

          <div className={style.ConfirmActions}>
            <button
              name="confirm"
              className={style.Confirm}
              onClick={onConfirm}
            >
              Submit attendance
            </button>
            <button
              name="decline"
              className={style.Decline}
              onClick={onDecline}
            >
              Sorry, {rsvp.attendingMax > 1 ? "we are" : "I'm"} unable to attend
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const RSVPControls = ({ rsvp, onDown, onUp }) => {
  return (
    <>
      <div className={style.SeatActions}>
        <button
          className={style.UPLeft}
          onClick={onDown}
          disabled={rsvp.attending <= 1}
        >
          -
        </button>
        <input
          name="seats"
          value={rsvp.attending}
          readOnly
          className={style.Seats}
        />
        <button
          className={style.UDRight}
          onClick={onUp}
          disabled={rsvp.attending >= rsvp.attendingMax}
        >
          +
        </button>
      </div>
    </>
  );
};

export default RSVPForm;
