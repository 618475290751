import style from "./Footer.module.css";
import LazyImage from "./LazyImage";
import LoRes from "../Assets/img/foliage-olive-branch-3.png";
import HiRes from "../Assets/img/foliage-olive-branch-3@2x.png";

const Footer = () => {
  return (
    <div className={style.Footer}>
      <div className={style.ThankYou}>Thank you!</div>
      <LazyImage loRes={LoRes} hiRes={HiRes} className={style.Leaf} />
    </div>
  );
};

export default Footer;
